<template>
    <Multiselect v-bind="$attrs"
                 :options="options"
                 :value="customers.find(c => c.id === value)"
                 @input="$emit('input', $event ? $event.id : null)"
                 :internal-search="false"
                 @search-change="query = $event"
                 track-by="id"
                 placeholder="Select a customer"
                 select-label=""
                 deselect-label="Selected"
                 :max-height="200">
        <template #option="{ option }">
            <span class="badge badge-soft-secondary align-text-bottom">{{ option.identifier }}</span>
            {{ option.name }}
        </template>
        <template #singleLabel="{ option }">
            <span class="badge badge-soft-secondary align-text-bottom">{{ option.identifier }}</span>
            {{ option.name }}
        </template>
    </Multiselect>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'CustomerSelect',
        components: {
            Multiselect,
        },
        props: {
            value: { type: undefined, required: true },
            customers: { type: Array, required: true },
        },
        data() {
            return {
                query: '',
            };
        },
        computed: {
            options() {
                if (this.query) {
                    const upperQuery = this.query.toUpperCase();
                    return this.customers.filter(customer => (
                        customer.name.toUpperCase().includes(upperQuery) ||
                        customer.identifier.toUpperCase().includes(upperQuery)
                    ));
                } else {
                    return this.customers.slice(); // Make a shallow copy for consistency
                }
            },
        },
    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
    .multiselect__tags {
        border: 1px solid #d8e2ef;
        min-height: 36px;
        height: 36px;
        padding-top: 5px;
    }

    .multiselect__placeholder {
        color: #d8e2ef;
    }

    .multiselect__option--highlight {
        background: #2c7be5 !important;
    }

    .multiselect__option--highlight:after {
        background: None !important;
    }

    .typo__label {
        color: #ca2a2a;
    }
</style>
